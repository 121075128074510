import { CustomViewEditorContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/customViewEditor/CustomViewEditorContext';
import { useContext } from 'react';
import { SaveModal } from './SaveModal';

export function SaveViewModal(props: { existingViews: Array<string> }) {
    const {
        customViewEditorState: { openSaveConfirmationModal },
        customViewEditorStateDispatcher,
    } = useContext(CustomViewEditorContext);

    return (
        <SaveModal
            openModal={openSaveConfirmationModal}
            callbackOnClose={() => {
                customViewEditorStateDispatcher({ type: 'CloseSaveConfirmationModal' });
            }}
            saveType='View'
            onPositiveAction={() => {
                customViewEditorStateDispatcher({ type: 'CloseSaveConfirmationModalAndCustomViewEditor' });
            }}
            existingNames={props.existingViews}
        />
    );
}
