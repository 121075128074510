import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils';
import { Divider, Tabs as MUITabs, SxProps, Tab, ThemeProvider, createTheme } from '@mui/material';

const tabsTheme = createTheme({
    components: {
        MuiTabs: {
            styleOverrides: {
                scrollButtons: {
                    '&.Mui-disabled': {
                        width: 0,
                        opacity: 0,
                    },
                },
                indicator: {
                    backgroundColor: '#3078B5',
                    height: 2,
                    webkitTransition: 'none',
                    transition: 'none',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    height: 50,
                    color: '#0B2958',
                    fontFamily: fontFamilies.GraphikRegular,
                    fontSize: 15,
                    textTransform: 'none',
                    padding: '13px 0px',
                    marginLeft: 10,
                    marginRight: 10,
                    '&:first-of-type': {
                        marginLeft: 0,
                    },
                    '&:last-child': {
                        marginRight: 0,
                    },
                    '&.Mui-selected': {
                        color: '#3078B5',
                    },
                    '&[role="divider"]': {
                        margin: 0,
                    },
                    minWidth: 10,
                    alignItems: 'baseline',
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    width: '2px',
                    height: '27px',
                    backgroundColor: '#EDF0F6',
                },
            },
        },
    },
});

export function Tabs(props: {
    activeTab: number | false;
    onChange: (event: React.SyntheticEvent<Element, Event>, value: any) => void;
    tabItems: Array<string>;
    tabWithRightDividerIndex?: number;
    ariaLabel: string;
    sx?: SxProps;
}) {
    const children: Array<React.ReactNode> = [];
    props.tabItems.forEach((tabName, index) => {
        children.push(<Tab key={index} label={tabName} value={index} />);
        if (props.tabWithRightDividerIndex && index === props.tabWithRightDividerIndex) {
            children.push(<Tab key={'divider'} label='' icon={<Divider />} disabled role={'divider'} />);
        }
    });

    return (
        <ThemeProvider theme={tabsTheme}>
            <MUITabs
                aria-label={props.ariaLabel}
                variant='scrollable'
                allowScrollButtonsMobile
                value={props.activeTab}
                onChange={props.onChange}
                sx={props.sx}>
                {children}
            </MUITabs>
        </ThemeProvider>
    );
}
