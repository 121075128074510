import { ChipAction, ChipState } from './types';

export function chipStateReducer(state: ChipState, action: ChipAction): ChipState {
    if (action.type === 'SetResultCount' && typeof action.newState?.resultCount === 'number') {
        return { ...state, resultCount: action.newState?.resultCount };
    }
    if (action.type === 'ClearChips') {
        //return { ...state, chipItems: undefined, isClearForm: true };
        return { ...state, chipItems: undefined, action: 'Clear' };
    }
    if (action.type === 'SetChipsData') {
        // return { ...state, chipItems: action.newState?.chipItems, isClearField: false };
        return { ...state, chipItems: action.newState?.chipItems, action: '' };
    }
    if (action.type === 'SetChipDirtyState' && action.newState?.field) {
        //return { ...state, field: action.newState?.field, isClearField: true };
        return { ...state, field: action.newState?.field, action: 'Dirty' };
    }
    if (action.type === 'ClearAction') {
        return { ...state, action: '' };
    }
    return state;
}
