import { ScreenerEtfData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { getRequestQuery } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { ETFSearchByParams, GetData, UseData, prefetchQuery } from 'utils';

export function getScreenerData({
    postData,
    securityType = 'etf',
    view = 'default',
    ssrmStartRowIndex,
    ssrmRowsToFetch,
    usePrefetchQuery = false,
    includeData,
    includeMetadata,
}: ETFSearchByParams) {
    const requestQuery = getRequestQuery(
        { securityType, view, ssrmStartRowIndex, ssrmRowsToFetch, includeData, includeMetadata },
        'screener',
    );
    const queryKey = [
        'getScreenerEtfData',
        securityType,
        view,
        postData,
        ssrmStartRowIndex,
        ssrmRowsToFetch,
        includeData,
        includeMetadata,
    ];

    if (usePrefetchQuery) {
        return prefetchQuery({ requestQuery, queryKey, useOpenSearch: true, requestBody: postData });
    }

    return UseData<ScreenerEtfData>({
        requestQuery,
        queryKey,
        useOpenSearch: true,
        requestBody: postData,
    });
}

export function getScreenerDataSSR({
    postData,
    securityType = 'etf',
    view = 'default',
    ssrmStartRowIndex,
    ssrmRowsToFetch,
    sortDirection,
    orderBy,
}: ETFSearchByParams) {
    const requestQuery = getRequestQuery(
        { securityType, view, ssrmStartRowIndex, ssrmRowsToFetch, sortDirection, orderBy },
        'screener',
    );
    const queryKey = [
        'getScreenerEtfData',
        securityType,
        view,
        postData,
        ssrmStartRowIndex,
        ssrmRowsToFetch,
        sortDirection,
        orderBy,
    ];

    return GetData<ScreenerEtfData>({
        requestQuery,
        queryKey,
        useOpenSearch: true,
        requestBody: postData,
    });
}

export function getScreenerReqBody(filtersData?: Object | undefined, fieldsData?: Object | undefined) {
    return filtersData || fieldsData
        ? {
              ...(filtersData && { filters: { values: filtersData } }),
              ...(fieldsData && { fields: { values: fieldsData } }),
          }
        : undefined;
}
