import { Modal } from '@cfra-nextgen-frontend/shared/src/components/Screener/Modal';
import SaveModalForm, { SaveModalFormProps } from './SaveModalForm';

export function SaveModal(props: {
    openModal: boolean;
    callbackOnClose: () => void;
    saveType: SaveModalFormProps['saveType'];
    onPositiveAction: () => void;
    existingNames: Array<string>;
}) {
    return (
        <Modal
            title={`Save ${props.saveType}`}
            openModal={props.openModal}
            callbackOnClose={props.callbackOnClose}
            modalBoxStyles={{
                padding: '32px 30px',
                maxWidth: '480px',
                height: 'unset',
            }}
            closeButtonFontSize={20}
            titleAndDescriptionContainerStyle={{
                padding: '0px',
                fontWeight: 500,
            }}>
            {props.openModal && (
                <SaveModalForm
                    saveType={props.saveType}
                    onPositiveAction={props.onPositiveAction}
                    positiveBtnText='Save'
                    existingNames={props.existingNames}
                />
            )}
        </Modal>
    );
}
