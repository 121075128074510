import { createContext, useMemo, useReducer } from 'react';
import { saveScreenReducer } from './Reducer';
import { DispatcherState, State } from './types';

export const SaveScreenContext = createContext<DispatcherState>({} as DispatcherState);

const InitialState: State = {
    openModal: false,
};

export function SaveScreenContextProvider({ children }: { children: React.ReactNode }) {
    const memorizedChildren = useMemo(() => children, [children]);
    const [state, stateDispatcher] = useReducer(saveScreenReducer, InitialState);

    const contextValue = {
        saveScreenState: state,
        saveScreenActionDispatcher: stateDispatcher,
    };

    return <SaveScreenContext.Provider value={contextValue}>{memorizedChildren}</SaveScreenContext.Provider>;
}
