import { RoundedTextButton } from '@cfra-nextgen-frontend/shared/src/components/ETFButton';
import { roundedTextButtonThemeV2 } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ButtonsThemes';
import { Box } from '@mui/material';
import { Modal } from './Modal';

export function ConfirmationModal(props: {
    openModal: boolean;
    modalText: string;
    confirmButtonText: string;
    confirmCallback: () => void;
    cancelButtonText?: string;
    cancelCallback?: () => void;
}) {
    return (
        <Modal
            openModal={props.openModal}
            modalBoxStyles={{
                padding: '32px 30px 29px',
                display: 'flex',
                alignContent: 'start',
                flexDirection: 'column',
                maxWidth: 'unset',
                maxHeight: 'unset',
                width: 'unset',
                height: 'unset',
            }}
            showCloseButton={false}
            description={props.modalText}
            descriptionStyle={{ fontSize: '18px', display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', flexWrap: 'wrap' }}>
                {props.cancelButtonText && props.cancelCallback ? (
                    <Box sx={{ paddingTop: '18px', marginLeft: '7.5px', marginRight: '7.5px' }}>
                        <RoundedTextButton
                            outlined
                            theme={roundedTextButtonThemeV2}
                            buttonText={props.cancelButtonText}
                            onClickCallback={props.cancelCallback}
                        />
                    </Box>
                ) : null}
                <Box sx={{ paddingTop: '18px', marginLeft: '7.5px', marginRight: '7.5px' }}>
                    <RoundedTextButton
                        theme={roundedTextButtonThemeV2}
                        buttonText={props.confirmButtonText}
                        onClickCallback={props.confirmCallback}
                    />
                </Box>
            </Box>
        </Modal>
    );
}
