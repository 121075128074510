import { ETFMenu, IconButton } from '@cfra-nextgen-frontend/shared';
import BookmarkIcon from '@cfra-nextgen-frontend/shared/src/assets/images/BookmarkIcon.svg';
import PencilIcon from '@cfra-nextgen-frontend/shared/src/assets/images/PencilIcon.svg';
import PlusIcon from '@cfra-nextgen-frontend/shared/src/assets/images/PlusIcon.svg';
import RedBinIcon from '@cfra-nextgen-frontend/shared/src/assets/images/RedBinIcon.svg';
import { LinkButton } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ETFLinkButton';
import { ResultsContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { TransparentButtonChip } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsPanelRowStyle';
import { SaveScreenContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/saveScreenerContext/Context';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils';
import { Box, styled } from '@mui/material';
import { useContext } from 'react';

type SavedScreensMenuProps = {};

export function SavedScreenMenu(props: SavedScreensMenuProps) {
    const cardName = 'my screen menu';
    const {
        chipStateManager: { chipState },
    } = useContext(ResultsContext);

    const myScreenMenuItems = [
        { itemName: <ScreenerMenuItem label='Search criteria A' />, callback: () => {} },
        { itemName: <ScreenerMenuItem label='Search criteria B' />, callback: () => {} },
        {
            itemName: <SaveScreenMenuItemButton cardName={cardName} />,
            disabled: !(chipState?.chipItems && Object.keys(chipState?.chipItems).length > 0),
            callback: () => {},
        },
    ];

    const menuItemStyles = {
        padding: '0px 13px 0px 0px',
        ':not(:last-child)': {
            borderBottom: 0,
        },
    };

    return (
        <ETFMenu.ETFMenu
            menuStyles={{ padding: '11px 0' }}
            menuItemStyles={menuItemStyles}
            menuItems={myScreenMenuItems}
            selectedItemIndex={0}
            borderOnHover={false}>
            <TransparentButtonChip
                key='My Screens'
                text='My Screens'
                startIcon={<Box component='img' src={BookmarkIcon} />}
            />
        </ETFMenu.ETFMenu>
    );
}

export function ScreenerMenuItem({ label }: { label: string }) {
    const MenuItemContainer = styled(Box)(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        paddingRight: '5px',
        cursor: 'default',
    }));

    const ItemLabelButton = styled(LinkButton)(({ theme }) => ({
        paddingTop: '7px',
        paddingBottom: '7px',
        paddingLeft: '21px',
        fontSize: '13px',
        fontFamily: fontFamilies.GraphikRegular,
        color: '#0B2958',
        fontWeight: '400',
        lineHeight: '22px',
        letterSpacing: '0.46px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '24ch',
        minWidth: '20.5ch',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        '&:hover': {
            color: '#007BB8',
            backgroundColor: '#FFF',
        },
    }));

    return (
        <MenuItemContainer>
            <ItemLabelButton>{label}</ItemLabelButton>
            <IconButton icon={<Box component='img' src={PencilIcon} />} tooltipText='Edit Name' />
            <IconButton icon={<Box component='img' src={RedBinIcon} />} tooltipText='Delete' />
        </MenuItemContainer>
    );
}

const SaveScreenMenuItemButton = ({ cardName }: { cardName: string }) => {
    const ButtonText = 'Save New Screen';
    const { saveScreenActionDispatcher } = useContext(SaveScreenContext);

    const SaveScreenButton = styled(LinkButton)(({ theme }) => ({
        paddingTop: '7px',
        paddingBottom: '7px',
        paddingLeft: '21px',
        fontFamily: fontFamilies.GraphikSemibold,
        fontSize: '13px',
        fontWeight: 600,
        lineHeight: '22px',
        letterSpacing: '0.46px',
        width: '100%',
        display: 'flex',
        gap: '8px',
        justifyContent: 'flex-start',
    }));

    const handleOnClick = () => {
        globalThis.analytics?.registerAction?.({
            action: `click on ${ButtonText}`,
            cardName: cardName,
        });
        saveScreenActionDispatcher({ type: 'OpenSaveScreenModal' });
    };

    return (
        <SaveScreenButton onClick={handleOnClick}>
            <Box component='img' src={PlusIcon} />
            <Box sx={{ color: '#3078B5', pt: '1px', lineHeight: '21px' }}>{ButtonText}</Box>
        </SaveScreenButton>
    );
};
