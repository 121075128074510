import { MetadataFieldDefinition } from '@cfra-nextgen-frontend/shared/src/components/types/fieldMetadata';
import {
    formatNumberTo2DecimalPlaces,
    getMomentObjectFrom,
    getNumberWithCommas,
    getValueByPath,
    removeInaccuracy,
    scaleCharacterToNumber,
} from '@cfra-nextgen-frontend/shared/src/utils';
import { ValueFormatterFunc } from 'ag-grid-community';
import { defaultValueTemplate, fillTemplate } from './templates';

export function applyScale({
    fieldMetadata,
    value,
}: {
    fieldMetadata: MetadataFieldDefinition;
    value: number;
}): number {
    if (fieldMetadata.scale) {
        if (!['h', 'k', 'm', 'b'].includes(fieldMetadata.scale.toLocaleLowerCase())) {
            throw new Error(
                `The scale for ${fieldMetadata.label} column contains value out of expected range - "${fieldMetadata.scale}".`,
            );
        }
        const isLowercaseScale = /^[a-z]+$/.test(fieldMetadata.scale);

        const coefficient =
            scaleCharacterToNumber[fieldMetadata.scale.toLocaleLowerCase() as keyof typeof scaleCharacterToNumber];

        if (coefficient) {
            return removeInaccuracy(isLowercaseScale ? value * coefficient : value / coefficient);
        }
    }
    return value;
}

function applyNumberFormat(fieldMetadata: MetadataFieldDefinition, value: number): string {
    const throwFormatError = () => {
        throw new Error(
            `The format for ${fieldMetadata.label} column contains value out of expected range - "${fieldMetadata.format}".`,
        );
    };

    if (!fieldMetadata.format) {
        throw new Error(`The format for ${fieldMetadata.label} is empty.`);
    }

    if (!fieldMetadata.format.includes('#') && !fieldMetadata.format.includes('.')) {
        throwFormatError();
    }

    let result;

    if (fieldMetadata.format.includes('#')) {
        result = String(Math.round(value));
    } else {
        let splittedFormat = fieldMetadata.format.split('.');
        if (splittedFormat.length < 2) {
            throwFormatError();
        }

        if (fieldMetadata.rounding_method === 'cut off') {
            result = formatNumberTo2DecimalPlaces(value, true);
        } else {
            result = parseFloat(String(value)).toFixed(splittedFormat[1].length);
        }
    }

    return result;
}

export function formatNumber({
    fieldMetadata,
    value,
    applyCommas = true,
    skipScale,
}: {
    fieldMetadata: MetadataFieldDefinition;
    value: number;
    applyCommas?: boolean;
    skipScale?: boolean;
}): string {
    if (!skipScale) {
        value = applyScale({ fieldMetadata, value });
    }

    let result = applyNumberFormat(fieldMetadata, value);

    if (applyCommas) {
        result = getNumberWithCommas(result);
    }

    return result;
}

function formatDate(fieldMetadata: MetadataFieldDefinition, value: string): string {
    const standardInputDateFormat = 'DD/MM/YYYY';
    return getMomentObjectFrom(value, standardInputDateFormat).format(fieldMetadata.format);
}

const defaultNoResultsSymbol = '-';

export function getScreenerValueFormatter(fieldMetadata: MetadataFieldDefinition): ValueFormatterFunc {
    return function (params): string {
        if (!params.colDef.field) {
            return defaultNoResultsSymbol;
        }

        const rawValue = getValueByPath(params.data, params.colDef.field);

        return applyFieldMetadataToValue({ fieldMetadata, rawValue });
    };
}

export function applyFieldMetadataToValue({
    fieldMetadata,
    rawValue,
    skipValueTemplate,
    skipScale,
}: {
    fieldMetadata: MetadataFieldDefinition;
    rawValue: any;
    skipValueTemplate?: boolean;
    skipScale?: boolean;
}) {
    if (rawValue === null || rawValue === undefined) {
        return defaultNoResultsSymbol;
    }

    if (!['string', 'number', 'date'].includes(fieldMetadata.type)) {
        throw new Error(`Pointed invalid column metadata type - "${fieldMetadata.type}"`);
    }

    let result = defaultNoResultsSymbol;

    if (fieldMetadata.type === 'string') {
        result = String(rawValue);
    }

    if (fieldMetadata.type === 'number') {
        result = formatNumber({
            fieldMetadata: fieldMetadata,
            value: rawValue,
            skipScale: skipScale,
        });
    }

    if (fieldMetadata.type === 'date') {
        result = formatDate(fieldMetadata, rawValue);
    }

    if (skipValueTemplate) {
        return result;
    }

    const valueTemplate =
        !fieldMetadata.value_template && fieldMetadata.symbol === '%'
            ? defaultValueTemplate
            : fieldMetadata.value_template;
    if (valueTemplate) {
        result = fillTemplate({
            template_name: 'value_template',
            template: valueTemplate,
            fieldMetadata: fieldMetadata,
            formattedValue: result,
        });
    }

    return result;
}

export const sliderApplyScaleToValue = ({
    value,
    isPercentageDataPoint,
    itemMetadata,
}: {
    value: number;
    isPercentageDataPoint: boolean;
    itemMetadata: MetadataFieldDefinition;
}) => {
    return applyScale({
        fieldMetadata: itemMetadata,
        value: isPercentageDataPoint ? Number(Math.ceil(value * 10000) / 10000) : Math.ceil(value),
    });
};

export function sliderUnApplyScaleFromNumericValue(
    fieldMetadata: MetadataFieldDefinition,
    valueWithAppliedFieldMetadata: string,
) {
    const parsedInputValue = parseFloat(valueWithAppliedFieldMetadata.replaceAll(',', ''));
    if (!fieldMetadata.scale) {
        return parsedInputValue;
    }
    const isLowerCaseScale = fieldMetadata.scale.toLocaleLowerCase() === fieldMetadata.scale;
    return applyScale({
        fieldMetadata: {
            ...fieldMetadata,
            scale: (isLowerCaseScale
                ? fieldMetadata.scale.toUpperCase()
                : fieldMetadata.scale.toLowerCase()) as typeof fieldMetadata.scale,
        },
        value: parsedInputValue,
    });
}
