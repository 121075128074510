import { CustomViewEditorContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/customViewEditor/CustomViewEditorContext';
import { FiltersModalContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import { ResultsContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { SaveScreenContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/saveScreenerContext/Context';
import { ScreenerViewContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerViewContext/Context';
import { CustomViewEditorModal } from './CustomViewEditorModal';
import { FiltersModal } from './FiltersModal';
import { SaveScreenModal } from './SaveScreenModal';
import { ScreenerCard } from './ScreenerCard';

export function ScreenerHome() {
    const etfScreenerCardName = 'ETF Screener';

    return (
        <SaveScreenContextProvider>
            <CustomViewEditorContextProvider>
                <ScreenerViewContextProvider>
                    <FiltersModalContextProvider>
                        <ResultsContextProvider>
                            <ScreenerCard cardName={etfScreenerCardName} />
                            <FiltersModal etfScreenerCardName={etfScreenerCardName} />
                        </ResultsContextProvider>
                    </FiltersModalContextProvider>
                    <CustomViewEditorModal />
                </ScreenerViewContextProvider>
                <SaveScreenModal />
            </CustomViewEditorContextProvider>
        </SaveScreenContextProvider>
    );
}
