import { defaultMinWidth } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGrid';
import { IServerSideGetRowsParams, SortModelItem } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/types';
import { ETFCard, NoInformationAvailable } from '@cfra-nextgen-frontend/shared/src/components/ETFCard';
import { FiltersModalContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import {
    ScreenerResultsChipComponents,
    ScreenerResultsCountPanel,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultPanelRow';
import { ResultsContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { ScreenerViewContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerViewContext/Context';
import { ScreenerEtfData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import {
    ssrmMaxRowsToFetch,
    watchListColumnWidth,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/Constants';
import { extractFromScreenerData } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/columnDefs';
import { Box, Stack } from '@mui/material';
import { AgGirdCard, TopPanelRow } from 'components/AgGrid/AgGridCard';
import { CardWithAgGridTopPanel } from 'components/AgGrid/CardWithAgGridTopPanel';
import { ScreenerViews } from 'features/etfScreener/components/ScreenerViews';
import { useContext, useEffect } from 'react';
import { UseQueryResult } from 'react-query';
import { getScreenerData, getScreenerDataSSR, getScreenerReqBody } from '../api/screener';
import { CustomViewEditorModalOpenButton } from './CustomViewEditorModal';
import { FiltersModalOpenButton, SaveScreenButton } from './FiltersModal';
import { SavedScreenMenu } from './SavedScreensMenu';

type ScreenerCardProps = {
    cardName: string;
};

export function ScreenerCard({ cardName }: ScreenerCardProps) {
    const { filtersPostData } = useContext(FiltersModalContext);
    const {
        chipStateManager: { chipState, chipStateDispatcher },
    } = useContext(ResultsContext);
    const { screenerViewState } = useContext(ScreenerViewContext);

    const screenerData = getScreenerData({
        postData: getScreenerReqBody(filtersPostData, screenerViewState.screenerActiveView.fieldsData),
        view: screenerViewState.screenerActiveView.key,
        ssrmStartRowIndex: 0,
        ssrmRowsToFetch: ssrmMaxRowsToFetch,
    }) as UseQueryResult<ScreenerEtfData>;

    const cardLabelProps = { paddingBottom: '12px', fontSize: '26px' };

    useEffect(() => {
        if (!chipStateDispatcher) {
            return;
        }

        if (screenerData.isLoading) {
            chipStateDispatcher({ type: 'SetResultCount', newState: { resultCount: -1 } });
            return;
        }

        chipStateDispatcher({
            type: 'SetResultCount',
            newState: {
                resultCount: screenerData.data?.results.total || 0,
            },
        });
    }, [screenerData.isLoading, screenerData.data, screenerData.data?.results.etf_data, chipStateDispatcher]);

    const chipComponents = ScreenerResultsChipComponents();

    const topPanelSlot1Content = (
        <TopPanelRow>
            <Stack direction='row' flexWrap='wrap' display='flex' maxWidth={'100%'} alignItems='center' marginY={1}>
                <div key='ScreenerFilterButtonDiv' style={{ margin: '4px 18px 4px 0' }}>
                    <FiltersModalOpenButton cardName={cardName} />
                </div>
                {chipState?.chipItems && Object.keys(chipState?.chipItems).length > 0 && (
                    <>
                        {chipComponents.chipList}
                        <Box display='flex' sx={{ mx: '6px' }}>
                            {chipComponents.clearButton}
                            <SaveScreenButton cardName={cardName} />
                        </Box>
                    </>
                )}
            </Stack>
        </TopPanelRow>
    );

    const screenerViewTabs = (
        <Box
            sx={{
                display: 'flex',
                width: 'calc(100% - 2 * 30px)',
                paddingLeft: '30px',
                paddingRight: '30px',
                alignItems: 'center',
                borderBottom: '1px solid #E4E5E9',
            }}>
            <Box sx={{ display: 'flex', overflowX: 'auto' }}>
                <ScreenerViews />
            </Box>
            <Box sx={{ marginLeft: '20px' }}>
                <CustomViewEditorModalOpenButton cardName={cardName} />
            </Box>
        </Box>
    );

    if (screenerData.isLoading) {
        return (
            <CardWithAgGridTopPanel
                label={cardName}
                topPanelSlot1Content={topPanelSlot1Content}
                topPanelSlot2Content={screenerViewTabs}
                labelProps={cardLabelProps}>
                <ETFCard isLoading={screenerData.isLoading} />
            </CardWithAgGridTopPanel>
        );
    }

    if (
        !screenerData.data ||
        !screenerData.data._metadata ||
        !screenerData.data._metadata.fields ||
        screenerData.data._metadata.fields.length === 0 ||
        !screenerData.data._viewdata ||
        !screenerData.data._viewdata.fields ||
        screenerData.data._viewdata.fields.length === 0 ||
        !screenerData.data.results ||
        !screenerData.data.results.etf_data ||
        screenerData.data.results.etf_data.length === 0
    ) {
        return (
            <CardWithAgGridTopPanel
                label={cardName}
                topPanelSlot1Content={topPanelSlot1Content}
                labelProps={cardLabelProps}>
                <NoInformationAvailable sx={{ width: '100%', paddingTop: '20px' }} />
            </CardWithAgGridTopPanel>
        );
    }

    const { minWidths, customFlexibleColumns, columnDefs } = extractFromScreenerData(screenerData.data, cardName);

    columnDefs.unshift({
        headerClass: 'ag-grid-selection-column',
        sortable: false,
        cellClass: 'ag-grid-cfra-watchlist-icons',
        checkboxSelection: true,
        width: watchListColumnWidth,
        minWidth: watchListColumnWidth,
    });

    const getSortFields = (sortModel: SortModelItem[]) => {
        let querySort = {};
        let getSortQueryField = (fieldName: string) =>
            screenerData.data._metadata.fields.filter((dict) => Object.keys(dict)[0] === fieldName)[0][fieldName]
                .source_field;

        if (sortModel.length > 0) {
            querySort = {
                sortDirection: sortModel.map((sort) => sort.sort).join(','),
                orderBy: sortModel.map((sort) => getSortQueryField(sort.colId)).join(','),
            };
        }
        return querySort;
    };

    const ssrDataExportFn = function (filtersPostData: Object | undefined) {
        return function (sortColumns: SortModelItem[]) {
            return getScreenerDataSSR({
                postData: getScreenerReqBody(filtersPostData),
                view: screenerViewState.screenerActiveView.key,
                ...getSortFields(sortColumns),
            }).then((response: any) => {
                return response?.results?.etf_data || [];
            });
        };
    };

    const dataSource = {
        getRows(params: IServerSideGetRowsParams) {
            if (
                params.request.sortModel.length === 0 &&
                (!params.request.startRow || (params.request.startRow && params.request.startRow === 0))
            ) {
                params.success({ rowData: screenerData.data.results.etf_data || [] });
            } else {
                return getScreenerDataSSR({
                    postData: getScreenerReqBody(filtersPostData),
                    view: screenerViewState.screenerActiveView.key,
                    ssrmStartRowIndex: params.request.startRow,
                    ssrmRowsToFetch: params.request.endRow,
                    ...getSortFields(params.request.sortModel),
                }).then((response: any) => {
                    if (response) {
                        params.success({ rowData: response.results.etf_data || [] });
                    }
                });
            }
        },
    };

    const getRowID = (params: any) => {
        return params.data.id;
    };

    const topRightActionPanelItems = [<SavedScreenMenu />];

    return (
        <AgGirdCard
            label={cardName}
            columnDefs={columnDefs}
            useSSRMode={true}
            SSRDataSource={dataSource}
            SSRrowsToFetch={ssrmMaxRowsToFetch}
            SSRDataExportFn={ssrDataExportFn(filtersPostData)}
            rowMultiSelectWithClick={true}
            rowSelection='multiple'
            getRowID={getRowID}
            rowsData={[]}
            customFlexibleColumns={customFlexibleColumns}
            getResizableMinWidthForColumn={(headerName: string) =>
                headerName === 'undefined' ? watchListColumnWidth : minWidths[headerName] || defaultMinWidth
            }
            excelExportAsOfDateField='fund_nav_data.as_of_date'
            topRightActionPanelItems={topRightActionPanelItems}
            topPanelSlot1Content={topPanelSlot1Content}
            topPanelSlot2Content={screenerViewTabs}
            topPanelSlot3Content={<ScreenerResultsCountPanel />}
            labelProps={cardLabelProps}
        />
    );
}
