import { PlusIconTextButton } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/PlusIconTextButton';
import { ConfirmationModal } from '@cfra-nextgen-frontend/shared/src/components/Screener/ConfirmationModal';
import { Modal } from '@cfra-nextgen-frontend/shared/src/components/Screener/Modal';
import { CustomViewEditorContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/customViewEditor/CustomViewEditorContext';
import { useContext } from 'react';
import { CustomViewEditorForm } from './CustomViewEditorForm';

export function CustomViewEditorModalOpenButton(props: { cardName: string }) {
    const { customViewEditorStateDispatcher } = useContext(CustomViewEditorContext);
    const createViewButtonText = 'Create View';
    return (
        <PlusIconTextButton
            onClickCallback={() => {
                globalThis.analytics?.registerAction?.({
                    action: `click on ${createViewButtonText}`,
                    cardName: props.cardName,
                });
                customViewEditorStateDispatcher({ type: 'OpenCustomViewEditor' });
            }}
            buttonText={createViewButtonText}
        />
    );
}

export function CustomViewEditorModal() {
    const {
        customViewEditorState: { openModal, isDirtyRightColumn, openExitConfirmationModal, openNoColumnsSelectedModal },
        customViewEditorStateDispatcher,
    } = useContext(CustomViewEditorContext);

    const confirmButtonText = 'Confirm';
    const cancelButtonText = 'Cancel';
    const okButtonText = 'OK';
    const customViewEditorTitle = 'Custom View Editor';
    const analyticsCardName = `${customViewEditorTitle} close confirmation popup`;

    return (
        <>
            <Modal // Custom View Editor modal
                title={customViewEditorTitle}
                openModal={openModal}
                callbackOnClose={() => {
                    if (isDirtyRightColumn) {
                        customViewEditorStateDispatcher({
                            type: 'OpenExitConfirmationModal',
                        });
                        return;
                    }
                    customViewEditorStateDispatcher({
                        type: 'CloseCustomViewEditor',
                    });
                }}
                modalBoxStyles={{
                    padding: '32px 30px 29px',
                    display: 'flex',
                    alignContent: 'start',
                    flexDirection: 'column',
                    maxWidth: '850px',
                    maxHeight: '650px',
                }}
                titleAndDescriptionContainerStyle={{
                    display: 'flex',
                }}
                titleAndDescriptionContainerXs={false}>
                <CustomViewEditorForm analyticsCardName={customViewEditorTitle} />
            </Modal>
            <ConfirmationModal // Custom View Editor close confirmation modal
                openModal={openExitConfirmationModal}
                modalText={`Exit without saving?`}
                cancelCallback={() => {
                    globalThis.analytics?.registerAction?.({
                        action: `click on ${cancelButtonText}`,
                        cardName: customViewEditorTitle,
                    });
                    customViewEditorStateDispatcher({
                        type: 'CloseExitConfirmationModal',
                    });
                }}
                cancelButtonText={cancelButtonText}
                confirmCallback={() => {
                    globalThis.analytics?.registerAction?.({
                        action: `click on ${confirmButtonText}`,
                        cardName: analyticsCardName,
                    });
                    customViewEditorStateDispatcher({
                        type: 'CloseExitConfirmationModalAndCustomViewEditorAndResetColumnsState',
                    });
                }}
                confirmButtonText={confirmButtonText}
            />
            <ConfirmationModal // Custom View Editor view now when no columns selected modal
                openModal={openNoColumnsSelectedModal}
                modalText={`Please add at least one column to your custom view.`}
                confirmCallback={() => {
                    globalThis.analytics?.registerAction?.({
                        action: `click on ${okButtonText}`,
                        cardName: analyticsCardName,
                    });
                    customViewEditorStateDispatcher({
                        type: 'CloseNoColumnsSelectedModal',
                    });
                }}
                confirmButtonText={okButtonText}
            />
        </>
    );
}
