import { SaveScreenContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/saveScreenerContext/Context';
import { useContext } from 'react';
import { SaveModal } from './SaveModal';

export function SaveScreenModal() {
    const {
        saveScreenState: { openModal },
        saveScreenActionDispatcher,
    } = useContext(SaveScreenContext);

    return (
        <SaveModal
            openModal={openModal}
            callbackOnClose={() => {
                saveScreenActionDispatcher({ type: 'CloseSaveScreenModal' });
            }}
            saveType='Screen'
            onPositiveAction={() => {
                saveScreenActionDispatcher({ type: 'CloseSaveScreenModal' });
            }}
            existingNames={['my screen 1', 'commodity_etf']}
        />
    );
}
