import { RoundedTextButton } from '@cfra-nextgen-frontend/shared';
import { roundedTextButtonThemeV2 } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ButtonsThemes';
import {
    StyledFormLabel,
    inputFontStyle,
} from '@cfra-nextgen-frontend/shared/src/components/Form/shared/StyledFormLabel';
import { fontFamilies, fontWeights } from '@cfra-nextgen-frontend/shared/src/utils';
import { Box, FormHelperText, SxProps, TextField, ThemeProvider, createTheme } from '@mui/material';
import { FieldErrors, useForm } from 'react-hook-form';

export type SaveModalFormProps = {
    saveType: 'Screen' | 'View';
    containerStyles?: SxProps;
    positiveBtnText?: string;
    positiveBtnDisabled?: boolean;
    negativeBtnText?: string;
    negativeBtnDisabled?: boolean;
    onPositiveAction?: () => void;
    existingNames?: string[];
};

type FormValues = {
    name: string;
};

const roundedTextButtonTheme = createTheme(roundedTextButtonThemeV2, {
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '13px',
                },
            },
        },
    },
});

const theme = createTheme({
    components: {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    ...inputFontStyle,
                },
                input: {
                    fontSize: '16px',
                    fontFamily: fontFamilies.GraphikRegular,
                    fontWeight: fontWeights.Regular,
                    lineHeight: '24px',
                    height: '36px',
                    padding: '2px 8px 2px 12px !important',
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    color: '#0B2958',
                    '& .Mui-error': {
                        color: '#D80027',
                    },
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    color: '#D80027',
                    margin: '1px 0px',
                    fontFamily: fontFamilies.GraphikRegular,
                    fontSize: '14px',
                    fontWeight: fontWeights.Regular,
                    fontStyle: 'normal',
                    minHeight: '13px',
                },
            },
        },
    },
});

export default function SaveModalForm(props: SaveModalFormProps) {
    const {
        saveType,
        containerStyles = {},
        positiveBtnText,
        positiveBtnDisabled,
        onPositiveAction = () => {},
        existingNames = [],
    } = props;

    const formConfigs = getFormConfigsBySaveType(saveType, existingNames);

    const {
        formState: { errors, isDirty },
        handleSubmit,
        register,
        reset,
    } = useForm<FormValues>({
        defaultValues: {
            name: '',
        },
        mode: 'onChange',
        reValidateMode: 'onSubmit',
    });

    const nameFieldControl = register('name', formConfigs?.validations);

    const analyticsCardName = `save${saveType}Modal`;

    const defaultContainerStyles: SxProps = {
        color: '#0B2958',
        fontFamily: fontFamilies.GraphikMedium,
        fontSize: '28px',
        fontWeight: 500,
        ...containerStyles,
    };

    const onSubmit = (data: FormValues) => {
        console.error(data);
        onPositiveAction();
        reset();
    };

    const onError = (errors: FieldErrors) => {};

    return (
        <form onSubmit={handleSubmit(onSubmit, onError)} noValidate>
            <ThemeProvider theme={theme}>
                <Box sx={defaultContainerStyles}>
                    <Box sx={{ paddingTop: '32px' }}>
                        <StyledFormLabel sx={{ fontSize: '16px', mt: '6px', mb: '10px' }}>Name</StyledFormLabel>
                        <TextField
                            id='name'
                            name={nameFieldControl.name}
                            inputRef={nameFieldControl.ref}
                            onBlur={nameFieldControl.onBlur}
                            onChange={nameFieldControl.onChange}
                            fullWidth
                            placeholder='Enter Name...'
                            autoComplete='off'
                            error={Boolean(errors?.name?.message)}
                            helperText={
                                <FormHelperText>
                                    {Boolean(errors?.name?.message) ? errors?.name?.message : ''}
                                </FormHelperText>
                            }
                        />
                    </Box>
                    <Box sx={{ py: '20px', display: 'flex', justifyContent: 'center', gap: '1rem' }}>
                        {/* can add cancel button if required */}
                        <RoundedTextButton
                            type='submit'
                            theme={roundedTextButtonTheme}
                            buttonText={positiveBtnText}
                            disabled={positiveBtnDisabled || !isDirty}
                            onClickCallback={() => {
                                globalThis.analytics?.registerAction?.({
                                    action: `click on ${positiveBtnText}`,
                                    cardName: analyticsCardName,
                                });
                            }}
                        />
                    </Box>
                </Box>
            </ThemeProvider>
        </form>
    );
}

function getFormConfigsBySaveType(saveType: SaveModalFormProps['saveType'], savedNames: string[]) {
    if (saveType === 'Screen' || saveType === 'View') {
        return {
            validations: {
                required: { value: true, message: '' },
                // maxLength: { value: 20, message: 'Name exceeds 20 characters limit' },
                validate: {
                    maxLengthExcludingSpace: (fieldValue: string) => {
                        return fieldValue.replace(/\s/g, '').length <= 20 || 'Name exceeds 20 characters limit';
                    },
                    duplicateName: (fieldValue: string) => {
                        return (
                            !savedNames.includes(fieldValue) ||
                            `${saveType} already exists. Please try a different name`
                        );
                    },
                },
            },
        };
    }
}
