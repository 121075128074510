import {
    ScreenerActiveViewLocalStorageKey,
    ScreenerCurrentViewLocalStorageKey,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerViewContext/Context';
import { setDataToLocalStorage } from '@cfra-nextgen-frontend/shared/src/utils/storage';
import { Action, State } from './types';

export function ScreenerViewReducer(state: State, action: Action): State {
    if (!action.newState) {
        throw new Error('Invalid ScreenerViewReducer new state.');
    }
    switch (action.type) {
        case 'setScreenerActiveView':
            setDataToLocalStorage(ScreenerActiveViewLocalStorageKey, action.newState);
            return { ...state, screenerActiveView: action.newState };
        case 'setScreenerActiveAndCurrentView':
            setDataToLocalStorage(ScreenerCurrentViewLocalStorageKey, action.newState);
            return { screenerActiveView: action.newState, screenerCurrentView: action.newState };
    }
}
