import {
    AllCustomViewsBaseDataResponse,
    CustomViewsExtendedDataResponse,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/types/views';
import { ApiNames } from '@cfra-nextgen-frontend/shared/src/utils';
import { SearchByParams, getRequestQuery } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { UseData, UseMultipleData } from 'utils';

export function getAllCustomViews({ types = [2] }: SearchByParams) {
    return UseData<AllCustomViewsBaseDataResponse>({
        requestQuery: getRequestQuery({ types: types }, 'saved-items'),
        queryKey: ['getAllCustomViews', types],
        apiName: ApiNames.UserManagement,
    });
}

export function getCustomViewDetails({ savedItems }: SearchByParams) {
    if (typeof savedItems != 'number') {
        throw new Error(`getCustomViewDetails received unexpected type of savedItems - ${typeof savedItems}`);
    }
    return UseData<CustomViewsExtendedDataResponse>({
        requestQuery: getRequestQuery({ savedItems: savedItems }),
        queryKey: ['getCustomViewDetails', savedItems],
        apiName: ApiNames.UserManagement,
    });
}

export function getCustomViewsDetails({ savedItems }: SearchByParams) {
    if (!Array.isArray(savedItems)) {
        throw new Error(`getCustomViewDetails received unexpected type of savedItems - ${typeof savedItems}`);
    }
    return UseMultipleData<CustomViewsExtendedDataResponse>(
        savedItems.map((item) => ({
            requestQuery: getRequestQuery({ savedItems: item }),
            queryKey: ['getCustomViewDetails', item],
            apiName: ApiNames.UserManagement,
        })),
    );
}
