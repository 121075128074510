import { FiltersModalContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import { ScreenerViewContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerViewContext/Context';
import {
    CustomViewBaseData,
    CustomViewExtendedData,
    ScreenerDefaultViews,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/types/views';
import { ssrmMaxRowsToFetch } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/Constants';
import { Tabs } from '@cfra-nextgen-frontend/shared/src/components/Tabs';
import { SxProps } from '@mui/material';
import { getAllCustomViews, getCustomViewsDetails } from 'features/etfScreener/api/customViews';
import { useContext } from 'react';
import { ETFSearchByParams, prefetchApiData } from 'utils';
import { getScreenerData, getScreenerReqBody } from '../api/screener';
import { SaveViewModal } from './SaveViewModal';

function prefetchScreenerData(requestArgsList: any[]) {
    prefetchApiData({
        requestArgsList: requestArgsList,
        requestCallback: ({ postData, view }: ETFSearchByParams) => {
            getScreenerData({
                postData,
                view,
                ssrmStartRowIndex: 0,
                ssrmRowsToFetch: ssrmMaxRowsToFetch,
                usePrefetchQuery: true,
            });
        },
    });
}

export function ScreenerViews() {
    const { screenerViewState, screenerViewActionDispatcher } = useContext(ScreenerViewContext);
    const { filtersPostData } = useContext(FiltersModalContext);
    const customViewsQueryResult = getAllCustomViews({});
    const defaultViews = Object.values(ScreenerDefaultViews);

    const customViews: Array<CustomViewBaseData> = [];
    // fill customViews only when general info about all views is loaded
    if (
        !customViewsQueryResult.isLoading &&
        customViewsQueryResult.data &&
        customViewsQueryResult.data.data &&
        customViewsQueryResult.data.data.length > 0
    ) {
        customViews.push(...customViewsQueryResult.data.data);
    }
    // fetch view details
    const customViewsDetailsQueryResults = getCustomViewsDetails({ savedItems: customViews.map((view) => view.id) });

    const customViewsDetails: Array<CustomViewExtendedData> = [];

    const isLoadedDetails = customViewsDetailsQueryResults.every(
        (customViewDetailsQueryResult) =>
            customViewDetailsQueryResult &&
            !customViewDetailsQueryResult.isLoading &&
            customViewDetailsQueryResult.data &&
            customViewDetailsQueryResult.data.data,
    );
    // fill customViewsDetails only after the details data requests is loaded
    if (isLoadedDetails) {
        customViewsDetailsQueryResults.forEach((customViewDetailsQueryResult) => {
            customViewsDetails.push(customViewDetailsQueryResult!.data!.data);
        });
    }

    // prefetch data for default views
    prefetchScreenerData(
        defaultViews.map((view) => ({
            postData: getScreenerReqBody(filtersPostData),
            view: view.key,
        })),
    );

    // prefetch custom views data
    prefetchScreenerData(
        customViewsDetails.map((view) => ({
            postData: getScreenerReqBody(filtersPostData, view.value),
            view: 'custom',
        })),
    );

    // prefetch current view data
    prefetchScreenerData([
        {
            postData: getScreenerReqBody(filtersPostData, screenerViewState.screenerCurrentView?.fieldsData),
            view: 'custom',
        },
    ]);

    const isCustomKey = screenerViewState.screenerActiveView.key === 'custom';
    const typeofLabelIsString = typeof screenerViewState.screenerActiveView.label === 'string';

    const isCurrentViewSelected = isCustomKey && !typeofLabelIsString;
    const isCustomViewSelected = isCustomKey && typeofLabelIsString;
    const isDefaultViewSelected = !isCustomKey && typeofLabelIsString;

    // get index of active tab based on screenerViewState
    function getActiveIndex(): number | false {
        // don't set active tab until the custom views is loaded
        if (!isLoadedDetails) {
            return false;
        }
        // handle current view selected
        if (isCurrentViewSelected) {
            return defaultViews.length + customViewsDetails.length;
        }
        // handle custom view selected
        if (isCustomViewSelected) {
            return (
                defaultViews.length +
                customViewsDetails.map((view) => view.name).indexOf(screenerViewState.screenerActiveView.label!)
            );
        }
        // handle default view selected
        if (isDefaultViewSelected) {
            return defaultViews.map((view) => view.label).indexOf(screenerViewState.screenerActiveView.label!);
        }

        throw new Error('Unable to get index of active view tab.');
    }

    function onChange(e: any, value: number) {
        // handle default view selection
        if (value <= defaultViews.length - 1) {
            screenerViewActionDispatcher({
                type: 'setScreenerActiveView',
                newState: defaultViews[value],
            });
            return;
        }

        // handle current view selection
        if (value === defaultViews.length + customViewsDetails.length) {
            screenerViewActionDispatcher({
                type: 'setScreenerActiveAndCurrentView',
                newState: {
                    key: 'custom',
                    fieldsData: screenerViewState?.screenerCurrentView?.fieldsData,
                    isCurrentView: true,
                },
            });
            return;
        }

        // handle custom view selection
        const customViewsIndex = value - defaultViews.length;
        screenerViewActionDispatcher({
            type: 'setScreenerActiveView',
            newState: {
                key: 'custom',
                label: customViewsDetails[customViewsIndex].name,
                fieldsData: customViewsDetails[customViewsIndex].value,
            },
        });
    }

    // add default and custom view tabs
    const tabItems = defaultViews.map((view) => view.label).concat(customViewsDetails.map((item) => item.name));

    // add current view tab
    if (screenerViewState.screenerCurrentView) {
        tabItems.push('Current View*');
    }

    // show current view tab label italic
    const currentViewTabStyles = screenerViewState.screenerCurrentView
        ? ({
              '& .MuiTab-root:last-of-type': {
                  fontStyle: 'italic',
              },
          } as SxProps)
        : undefined;

    return (
        <>
            <Tabs
                ariaLabel={'screener tabs'}
                activeTab={getActiveIndex()}
                onChange={onChange}
                tabItems={tabItems}
                tabWithRightDividerIndex={
                    // show divider only if present custom or current views
                    customViewsDetails.length > 0 && screenerViewState.screenerCurrentView
                        ? defaultViews.length - 1
                        : undefined
                }
                sx={currentViewTabStyles}
            />
            <SaveViewModal existingViews={customViews.map((view) => view.name)} />
        </>
    );
}
