import { SxProps, Theme } from '@mui/material/styles';
import { CommonButton } from './CommonButton';
import { IButtonProps } from './types';

type RoundedTextButtonTypes = IButtonProps & {
    theme: Theme;
    outlined?: boolean;
    disabled?: boolean;
    type?: 'button' | 'submit' | 'reset';
};

export function RoundedTextButton(props: RoundedTextButtonTypes) {
    const { theme, buttonText, outlined, onClickCallback, ...rest } = props;

    return (
        <CommonButton
            theme={theme}
            content={buttonText}
            onClickCallback={onClickCallback}
            outlined={outlined}
            {...rest}
        />
    );
}
