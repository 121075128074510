import { FiltersData } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { getRequestQuery } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { ETFSearchByParams, UseData } from 'utils';

export function getFiltersData({ postData, securityType = 'etf' }: ETFSearchByParams) {
    return UseData<FiltersData>({
        requestQuery: getRequestQuery({ securityType }, 'screener-filters'),
        queryKey: ['getFiltersData', securityType, postData],
        useOpenSearch: true,
        requestBody: postData,
    });
}

export function getFiltersReqBody(filtersData: Object | undefined) {
    return filtersData ? { ...(filtersData && { filters: { values: filtersData } }) } : undefined;
}
