import { Action, State } from './types';

export function customViewEditorReducer(state: State, action: Action): State {
    switch (action.type) {
        case 'SetResetColumnsStateDoneAndSetRightColumnClean':
            return { ...state, resetColumnsState: false, isDirtyRightColumn: false };
        case 'CloseExitConfirmationModalAndCustomViewEditorAndResetColumnsState':
            return { ...state, openExitConfirmationModal: false, openModal: false, resetColumnsState: true };
        case 'OpenNoColumnsSelectedModal':
            return { ...state, openNoColumnsSelectedModal: true };
        case 'CloseNoColumnsSelectedModal':
            return { ...state, openNoColumnsSelectedModal: false };
        case 'OpenCustomViewEditor':
            return { ...state, openModal: true };
        case 'CloseCustomViewEditor':
            return { ...state, openModal: false };
        case 'OpenExitConfirmationModal':
            return { ...state, openExitConfirmationModal: true };
        case 'CloseExitConfirmationModal':
            return { ...state, openExitConfirmationModal: false };
        case 'OpenSaveConfirmationModal':
            return { ...state, openSaveConfirmationModal: true };
        case 'CloseSaveConfirmationModal':
            return { ...state, openSaveConfirmationModal: false };
        case 'CloseSaveConfirmationModalAndCustomViewEditor':
            return { ...state, openSaveConfirmationModal: false, openModal: false };
        case 'SetRightColumnDirty':
            return { ...state, isDirtyRightColumn: true };
        case 'SetRightColumnClean':
            return { ...state, isDirtyRightColumn: false };
    }
}
