import { Action, State } from './types';

export function saveScreenReducer(state: State, action: Action): State {
    switch (action.type) {
        case 'OpenSaveScreenModal':
            return { ...state, openModal: true };
        case 'CloseSaveScreenModal':
            return { ...state, openModal: false };
    }
}
